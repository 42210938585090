import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainContent from './Homepage';
import ModpackPage from './components/ModpackPage';
import Header from './components/Header';
import PageTransition from './components/PageTransition';
import './App.scss';

function App() {

  return (
    <Router>
      <div className="app">
        <Header />
        <div className="main-container">
          <PageTransition>
            <Routes>
              <Route path="/" element={<MainContent />} />
              <Route path="/modpack" element={<ModpackPage />} />
            </Routes>
          </PageTransition>
        </div>
      </div>
    </Router>
  );
}

export default App;
