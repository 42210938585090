import React from 'react';
import '../scss/ServerStatusWidget.scss';

function ServerStatusWidget() {
  return (
    <div className="mcstatus-widget">
      <button
        className="bg-transparent hover:bg-transparent"
        onClick={() => navigator.clipboard.writeText('mc.spacetrainclubhouse.com')}
      >
        <img
          className="opacity-75 hover:opacity-90 transition-all duration-300"
          alt="Minecraft Server Status Widget"
          src={`https://api.mcstatus.io/v2/widget/java/mc.spacetrainclubhouse.com`}
          style={{ width: '100%', height: 'auto', maxWidth: '500px' }}
        />
      </button>
    </div>
  );
}

export default ServerStatusWidget;
