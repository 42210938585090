import React, {  } from 'react';
import { useNavigate } from 'react-router-dom';
import '../scss/Header.scss';
import logo from '../assets/images/stc_logo.png';

function Header() {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    if (window.location.pathname !== '/') {
      navigate('/');
    }
  };

  return (
    <header className="site-header">
      <div className="logo-container" onClick={handleLogoClick}>
        <img src={logo} alt="Space Train Clubhouse Logo" className="logo" />
        <div className="site-title">Space Train Clubhouse</div>
      </div>
      <div className="toggle-container"></div>
    </header>
  );
}

export default Header;
