import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ServerStatusWidget from './components/ServerStatusWidget';
import WorldMap from './components/WorldMap';
import './Homepage.scss';

function MainContent() {
  const [showWorldMap, setShowWorldMap] = useState(false);
  const navigate = useNavigate();

  const handleWorldMapClick = () => {
    setShowWorldMap(!showWorldMap);
  };

  return (
    <div className="page-container">
      <div className="main-content">
        <div className="discord-widget">
          <iframe
            src="https://discord.com/widget?id=280794720287064064&theme=dark"
            height="500"
            allowtransparency="true"
            title="Discord Widget"
            style={{ width: '100%', border: 'none', colorAdjust: 'exact', color: "white"}}
          ></iframe>
        </div>
        <div className="content-area">
          <div className="buttons">
            <button onClick={() => window.location.href = 'https://git.spacetrainclubhouse.com'}>
              Gitea
            </button>
            <button onClick={handleWorldMapClick}>
              Minecraft World Map
            </button>
            <button onClick={() => navigate('/modpack')}>
              Download Modpack
            </button>
          </div>
          <WorldMap show={showWorldMap} />
          <ServerStatusWidget />
        </div>
      </div>
    </div>
  );
}

export default MainContent;
