import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../scss/ImageCarousel.scss';

const PauseResumeButton = ({ isPlaying, togglePlayPause }) => (
  <button onClick={togglePlayPause} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
    {isPlaying ? (
      <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="1.414">
        <rect x="8" y="5" width="5" height="20" fill="#ffffff"/>
        <rect x="17" y="5" width="5" height="20" fill="#ffffff"/>
      </svg>
    ) : (
      <svg width="30" height="30" viewBox="0 0 0.6 0.6" xmlns="http://www.w3.org/2000/svg">
        <path fill="#ffffff" d="M.075.037v.525l.45-.263z"/>
      </svg>
    )}
  </button>
);

const ImageCarousel = () => {
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(1); // Start at 1 to handle duplicates
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const slideWrapperRef = useRef(null);
  const intervalRef = useRef(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const fetchImages = async () => {
      let images = [];
      if (process.env.NODE_ENV === 'development') {
        const context = require.context('../assets/gallery', false, /\.(png|jpe?g|svg)$/);
        images = context.keys().map(context);
      } else {
        const response = await fetch('/gallery/');
        const html = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const links = Array.from(doc.querySelectorAll('a'))
          .filter(link => link.href.match(/\.(jpeg|jpg|gif|png)$/))
          .map(link => '/gallery/' + link.textContent);
        images = links;
      }
      setImages([images[images.length - 1], ...images, images[0]]); // Duplicate first and last image
    };

    fetchImages();
  }, []);

  const handleNext = useCallback(() => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        // Wrap around to the second image when reaching the end
        if (nextIndex >= images.length) {
          return 1;
        }
        return nextIndex;
      });
    }
  }, [isTransitioning, images.length]);

  const handlePrev = useCallback(() => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex - 1;
        // Wrap around to the second-to-last image when reaching the beginning
        if (nextIndex < 0) {
          return images.length - 2;
        }
        return nextIndex;
      });
    }
  }, [isTransitioning, images.length]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'ArrowRight') {
      handleNext();
    } else if (event.key === 'ArrowLeft') {
      handlePrev();
    }
  }, [handleNext, handlePrev]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleIndicatorClick = ((index) => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setCurrentIndex(index);
      setProgress(0);
    }
  });

  const startAutoSlide = useCallback(() => {
    setProgress(0);
    intervalRef.current = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          handleNext();
          return 0;
        } else {
          return prevProgress + 2; // Increment by 2 every 100ms to reach 100 in 5 seconds
        }
      });
    }, 100);
  }, [handleNext]);

  const stopAutoSlide = useCallback(() => {
    clearInterval(intervalRef.current);
    setProgress(0);
  }, []);

  useEffect(() => {
    if (isPlaying) {
      startAutoSlide();
    } else {
      stopAutoSlide();
    }

    return () => stopAutoSlide();
  }, [isPlaying, startAutoSlide, stopAutoSlide]);

  const togglePlayPause = () => {
    setIsPlaying(prev => !prev);
  };

  useEffect(() => {
    const wrapper = slideWrapperRef.current;

    const handleTransitionEnd = () => {
      if (currentIndex === images.length - 1) {
        wrapper.style.transition = 'none';
        setTimeout(() => {
          setCurrentIndex(1);
          wrapper.style.transform = `translateX(-100%)`;
          setIsTransitioning(false);
          // Re-enable the transition effect after the currentIndex has been updated
          wrapper.style.transition = 'transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)';
        }, 500);
      } else if (currentIndex === 0) {
        wrapper.style.transition = 'none';
        setTimeout(() => {
          setCurrentIndex(images.length - 2);
          wrapper.style.transform = `translateX(-${(images.length - 2) * 100}%)`;
          setIsTransitioning(false);
          // Re-enable the transition effect after the currentIndex has been updated
          wrapper.style.transition = 'transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)';
        }, 500);
      } else {
        setIsTransitioning(false);
      }
    };

    wrapper.addEventListener('transitionend', handleTransitionEnd);

    return () => {
      wrapper.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [currentIndex, images.length]);

  useEffect(() => {
    const wrapper = slideWrapperRef.current;
    wrapper.style.transition = isTransitioning ? 'transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s' : 'none';
    wrapper.style.transform = `translateX(-${currentIndex * 100}%)`;
  }, [currentIndex, isTransitioning]);

  return (
    <div className="slideshow-container">
      <div className="arrow left-arrow" onClick={handlePrev}>&#10094;</div>
      <div className="arrow right-arrow" onClick={handleNext}>&#10095;</div>
      <div className="slides-wrapper" ref={slideWrapperRef}>
        {images.map((image, index) => (
          <div
            key={index}
            className={`mySlides ${index === currentIndex ? 'active' : ''}`}
            style={{
              opacity: index === currentIndex ? 1 : 0,
              transition: 'opacity 0.5s ease',
            }}
          >
            <img src={image} alt={`slide-${index}`} />
          </div>
        ))}
      </div>
      <div className="indicator-container">
        <div className="indicator-wrapper">
          {images.slice(1, images.length - 1).map((_, index) => (
            <div
              key={index}
              className={`indicator ${index + 1 === currentIndex ? 'active' : ''}`}
              onClick={() => handleIndicatorClick(index + 1)}
            >
              <div className="progress" style={{ width: `${index + 1 === currentIndex ? progress : 0}%` }} />
            </div>
          ))}
          <PauseResumeButton isPlaying={isPlaying} togglePlayPause={togglePlayPause} />
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
