import React, { useState, useEffect } from 'react';
import '../scss/FileList.scss';
import '../scss/common.scss';
import {mockFiles} from "../mockData";

function FileList() {

const [files, setFiles] = useState([]);
useEffect(() => {
  const fetchFiles = async () => {
    if (process.env.NODE_ENV === 'development') {
      const filteredMockFiles = mockFiles.filter(file => !file.name.startsWith('.'));
      setFiles(filteredMockFiles);
    } else {
      const response = await fetch('/files/');
      const html = await response.text();
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, 'text/html');
    const links = Array.from(doc.querySelectorAll('a'))
      .filter(link => !link.textContent.startsWith('.'))
      .map(link => ({
        name: link.textContent,
        url: '/files/' + link.textContent,
      }));
      const filePromises = links.map(async (link) => {
        const headResponse = await fetch(link.url, { method: 'HEAD' });
        const size = headResponse.headers.get('Content-Length');
        return {
          name: link.name,
          size: size ? (size / 1024 > 1024 ? (size / 1048576).toFixed(2) + ' MB' : (size / 1024).toFixed(2) + ' KB') : 'Unknown size',
          url: link.url,
        };
      });
      const resolvedFiles = await Promise.all(filePromises);
      setFiles(resolvedFiles);
    }
  };
  fetchFiles();
}, []);
  return (
    <ul className="file-list">
      {files.map((file, index) => (
        <div key={index} className="file-item">
          <a href={file.url} download>{file.name} [{file.size}]</a>
          <button onClick={() => window.location.href = file.url} className="file-button">Download</button>
        </div>
      ))}
    </ul>
  );
}

export default FileList;
