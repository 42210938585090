import React from 'react';
import '../scss/WorldMap.scss';

function WorldMap({ show }) {
  const handleFullScreen = () => {
    const iframe = document.querySelector('.world-map iframe');
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    }
  };

  return (
    <div className={`world-map ${show ? 'show' : ''}`}>
      <iframe
        src="https://map.spacetrainclubhouse.com/#solara:-1280:0:210:1500:0:0:0:0:perspective"
        title="Minecraft World Map"
        frameBorder="0"
      ></iframe>
      <button className="fullscreen-button" onClick={handleFullScreen}>
        <svg
          className="fill-slate-50"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M6 2L2 6V2H6Z" />
          <path d="M2 18L6 22H2V18Z" />
          <path d="M18 22L22 18V22H18Z" />
          <path d="M22 6L18 2H22V6Z" />
        </svg>
        </button>
    </div>
  );
}

export default WorldMap;
