import React, { useState } from 'react';
import '../scss/ModpackPage.scss';
import ServerStatusWidget from './ServerStatusWidget';
import WorldMap from './WorldMap';
import ImageCarousel from './ImageCarousel';
import FileList from './FileList';

function ModpackPage() {
  const [showWorldMap, setShowWorldMap] = useState(false);
  return (
    <div className="page-container">
      <ImageCarousel />
      <div className="modpack-info">
        <p>
          This modpack contains a curated selection of mods/shaders/resource packs to balance the combination of best visuals and performance. The server also supports voice chat, which is included in the modpack. The modpack also contains Distant Horizons, which allows for incredible draw distances and a more fantastic view of the world. Just download a minecraft instance manager/launcher (GDLauncher, Modrinth App, etc.) and import the modpack below.
        </p>
        <FileList />
        <div className="buttons">
          <button onClick={() => window.open('https://modrinth.com/app', '_blank')}>
            Modrinth App
          </button>
          <button onClick={() => window.history.back()}>
            Return to Home
          </button>
          <button onClick={() => setShowWorldMap(!showWorldMap)}>
            Minecraft World Map
          </button>
        </div>
        <WorldMap show={showWorldMap} />
        <ServerStatusWidget />
      </div>
    </div>
  );
}

export default ModpackPage;
